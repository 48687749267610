import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!
  const _component_el_timeline = _resolveComponent("el-timeline")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_timeline, null, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeline, (item, index) => {
          return (_openBlock(), _createBlock(_component_el_timeline_item, {
            key: index,
            timestamp: item.timestamp,
            placement: "top"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, null, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(item.title), 1),
                  _createElementVNode("p", null, _toDisplayString(item.content), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["timestamp"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}