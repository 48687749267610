import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-container" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserCard = _resolveComponent("UserCard")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_Activity = _resolveComponent("Activity")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_Timeline = _resolveComponent("Timeline")!
  const _component_Account = _resolveComponent("Account")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.user)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_el_row, { gutter: 20 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_col, {
                span: 6,
                xs: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_UserCard, { user: _ctx.user }, null, 8, ["user"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_col, {
                span: 18,
                xs: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_tabs, {
                        modelValue: _ctx.activeTab,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeTab) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_tab_pane, {
                            label: "Activity",
                            name: "activity"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Activity)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_tab_pane, {
                            label: "Timeline",
                            name: "timeline"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Timeline)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_tab_pane, {
                            label: "Account",
                            name: "account"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_Account, { user: _ctx.user }, null, 8, ["user"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}